import React, { Component } from "react";
import styled from "styled-components";

const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    z-index: 1;
    align-items: center;
    @media (max-width: 960px) {
        padding: 0px;
    }
    padding-top: 80px;
`;

const Wrapper = styled.div`
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    width: 100%;
    max-width: 1350px;
    padding: 0px 0px 80px 0px;
    gap: 12px;
    @media (max-width: 960px) {
        flex-direction: column;
    }
`;

const Title = styled.div`
    font-size: 42px;
    text-align: center;
    font-weight: 600;
    margin-top: 20px;
    color: ${({ theme }) => theme.text_primary};
    @media (max-width: 768px) {
        margin-top: 12px;
        font-size: 32px;
    }
`;

const Desc = styled.div`
    font-size: 18px;
    text-align: center;
    max-width: 600px;
    color: ${({ theme }) => theme.text_secondary};
    @media (max-width: 768px) {
        margin-top: 12px;
        font-size: 16px;
    }
`;

const ContactButton = styled.a`
    width: 100%;
    max-width: 307px;
    text-decoration: none;
    text-align: center;
    background: hsla(271, 100%, 50%, 1);
    background: linear-gradient(
        225deg,
        hsla(271, 100%, 50%, 1) 0%,
        hsla(294, 100%, 50%, 1) 100%
    );
    padding: 13px 16px;
    margin-top: 28px;
    border-radius: 12px;
    border: none;
    color: ${({ theme }) => theme.text_primary};
    font-size: 18px;
    font-weight: 600;
    cursor: pointer;
    display: flex; /* Use flex to align the text and image */
    align-items: center;
    justify-content: center;
    gap: 10px; /* Add some space between the text and the gif */
`;

const ContactGif = styled.img`
    width: 50px; /* Adjust the size of the GIF */
    height: auto;
`;

class Contact extends Component {
    render() {
        return (
            <Container>
                <Wrapper>
                    <Title>Contact</Title>
                    <Desc>
                        Feel free to reach out to me for any questions or
                        opportunities!
                    </Desc>
                    <ContactButton href="mailto:amit.korat@outlook.com">
                        <ContactGif
                            src="https://raw.githubusercontent.com/Amit-korat/images/main/email-file.gif"
                            alt="Email GIF"
                        />
                        Click to Email Me
                        <ContactGif
                            src="https://raw.githubusercontent.com/Amit-korat/images/main/mail-truck.gif"
                            alt="Mail truck GIF"
                        />
                    </ContactButton>
                </Wrapper>
            </Container>
        );
    }
}

export default Contact;
